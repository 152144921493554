import React from 'react'

const Experience = () => {
    return (
        <div className='flex flex-col our-experience-container text-white tw-font-light tw-text-center tw-align-middle tw-mt-20'>
            <div className="our-experience-header w-text-center tw-align-middle tw-p-10">
                7+ Years of Experience in Developing Cutting Edge <br />
                Blockchain and Web3 Solutions
            </div>
            <div className="our-experience-bottom tw-w-full text-2xl w-text-center tw-align-middle  tw-mb-20">
                we provide end to end support from design to production for your project
            </div>

            <div className="bar-wrapper tw-flex  w-text-center tw-align-middle">
                <div className="vertical-bar tw-h-36"></div>
            </div>

        </div>
    )
}

export default Experience
